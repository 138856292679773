<template>
  <div class="paginated_list">
    <ul class="list">
      <li v-for="(item, index) in visibleItems" :key="index" class="list-item">
        <slot :item="item" :idx="index" />
      </li>
    </ul>

    <base-pagination
      class="pagination"
      :pages="pages"
      :selected="currentPage"
      @[EVENTS.SELECT]="handlePaginate"
    />
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";

// COMPONENTS
import BasePagination from "@/components/shared/BasePagination/BasePagination";

export default {
  name: "PaginatedList",
  components: { BasePagination },
  props: {
    items: {
      type: Array,
      required: false,
      defalt: () => [],
    },
  },
  data() {
    return {
      EVENTS,
      maxPerPage: 50,
      currentPage: 1,
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.items.length / this.maxPerPage);
    },
    visibleItems() {
      return [...this.items].splice(
        (this.currentPage - 1) * this.maxPerPage,
        this.maxPerPage
      );
    },
  },
  methods: {
    handlePaginate(page) {
      this.currentPage = page;
    },
  },
};
</script>

<style lang="scss" scoped>
.paginated_list {
  position: relative;
  max-height: 100%;
  overflow: hidden;

  .list {
    padding: 10px;
    margin: 0;
    list-style: none;
    display: grid;
    gap: 12px;
    max-height: 100%;
    overflow: scroll;
  }

  .pagination {
    position: sticky;
    top: 100%;
    width: 100%;
  }
}
</style>
