<template>
  <ul class="word_bank">
    <li v-for="(word, idx) in words" class="word" :key="word">
      <p>{{ word }}</p>
      <base-button
        class="delete_btn"
        :id="`word_bank_item_${idx}_remove_btn`"
        text="X"
        :theme="'CLEAR'"
        @[EVENTS.CLICK]="() => $emit(EVENTS.REMOVE, word)"
      />
    </li>
  </ul>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";

// COMPONENTS
import BaseButton from "@/components/shared/BaseButton/BaseButton";

export default {
  name: "WordBank",
  components: { BaseButton },
  props: {
    words: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => {
    return {
      EVENTS,
    };
  },
};
</script>

<style lang="scss" scoped>
.word_bank {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  color: var(--snow-grey);
  margin: 0;

  .word {
    background-color: var(--aqua-blue);
    border-radius: 4px;
    padding: 5px;
    position: relative;
    padding-left: 18px;
    padding-top: 5px;
    font-family: var(--font-rubik);

    p {
      margin: 0;
    }

    .delete_btn {
      position: absolute;
      top: 5px;
      left: 5px;
      font-size: 10px;
      padding: 0;
      color: var(--white);
    }
  }
}
</style>
