<template>
  <div class="color_picker">
    <ul class="colors_list">
      <li
        v-for="color in colors"
        :class="{
          color_item: true,
          selected: color === selected,
        }"
        :key="color"
        :style="{
          '--background-color': color,
        }"
        @[EVENTS.CLICK]="() => $emit(EVENTS.SELECT, color)"
      ></li>
    </ul>
  </div>
</template>

<script>
import EVENTS from "@/enums/events";

export default {
  name: "ColorPicker",
  components: {},
  props: {
    colors: {
      type: Array,
      required: false,
      default: () => [],
    },
    selected: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      EVENTS,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.color_picker {
  .colors_list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;

    .color_item {
      height: 30px;
      width: 30px;
      border-radius: 4px;
      background-color: var(--background-color);
      border: 2px solid var(--snow-grey);

      &.selected {
        border: 2px solid var(--midnight-blue);
      }
    }
  }
}
</style>
