<template>
  <div class="base_number_input">
    <label v-if="label" :for="id">{{ label }}</label>
    <input
      :id="id"
      :name="id"
      :value="value"
      type="number"
      @[EVENTS.INPUT]="handleChange"
    />
  </div>
</template>

<script>
import EVENTS from "@/enums/events";

export default {
  name: "BaseNumberInput",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => {
    return {
      EVENTS,
    };
  },
  methods: {
    handleChange(e) {
      this.$emit(EVENTS.CHANGE, Number(e.target.value));
    },
  },
};
</script>

<style lang="scss" scoped>
.base_number_input {
  display: flex;
  flex-direction: column;

  input {
    display: block;
    border: 1px solid black;
    border-radius: 4px;
    padding: 10px;
    background: var(--snow-grey);
  }
}
</style>
