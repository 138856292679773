<template>
  <div
    :class="{
      screen_popup: true,
      overlay: overlay,
    }"
  >
    <div class="content">
      <div class="header">
        <base-button
          id="close_screen_popup_btn"
          class="close_btn"
          text="X"
          theme="CLEAR"
          @[EVENTS.CLICK]="$emit(EVENTS.CLOSE)"
        />
      </div>

      <slot name="content" />
    </div>
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";

// STYLES
import ICONS from "@/enums/icons";

// COMPONENTS
import BaseButton from "@/components/shared/BaseButton/BaseButton";

export default {
  name: "ScreenPopup",
  components: { BaseButton },
  props: {
    overlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      EVENTS,
      ICONS,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.screen_popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: auto;
  z-index: 10;

  &.overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  .content {
    position: fixed;
    width: max-content;
    height: max-content;
    background: var(--white);
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 6px;
    padding: 1rem;
    max-width: 100%;

    .header {
      display: flex;
      justify-content: flex-end;

      .close_btn {
        padding: 3px 5px;
        font-size: 16px;
      }
    }
  }
}
</style>
