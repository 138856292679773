var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"shape_picker"},[_c('li',{class:{
      square: true,
      shape: true,
      selected: _vm.selected === _vm.ENUMS.SHAPES.SQUARE,
    },on:_vm._d({},[_vm.EVENTS.CLICK,() => _vm.$emit(_vm.EVENTS.SELECT, _vm.ENUMS.SHAPES.SQUARE)])}),_c('li',{class:{
      circle: true,
      shape: true,
      selected: _vm.selected === _vm.ENUMS.SHAPES.CIRCLE,
    },on:_vm._d({},[_vm.EVENTS.CLICK,() => _vm.$emit(_vm.EVENTS.SELECT, _vm.ENUMS.SHAPES.CIRCLE)])})])
}
var staticRenderFns = []

export { render, staticRenderFns }