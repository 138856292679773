import store from "@/store/store";
import STORE_ENUMS from "@/store/enums";

const divideWorkers = (
  startIdx,
  endIdx,
  searchTerms,
  minSkip,
  maxSkip,
  matrixLetters
) => {
  const workers = [];
  for (let i = 0; i < searchTerms.length; i++) {
    const firstLetter = searchTerms[i].charAt(0);
    const workerExists = workers.find((w) => w.letter === firstLetter);
    if (workerExists) continue;

    workers.push({
      letter: firstLetter,
      searchTerms: searchTerms.filter((term) => term.charAt(0) === firstLetter),
      startIdx,
      endIdx,
      minSkip,
      maxSkip,
      matrixLetters,
    });
  }

  return workers;
};

const replaceSofit = (str) => {
  const mapObj = {
    ך: "כ",
    ם: "מ",
    ן: "נ",
    ף: "פ",
    ץ: "צ",
  };
  var re = new RegExp(Object.keys(mapObj).join("|"), "gi");

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
};

export const useSkipAlgorithm = async ({
  startIdx,
  endIdx,
  searchTerms,
  minSkip,
  maxSkip,
  onMessageCallback,
  matrixLetters,
}) => {
  // Indexes in algo start at 0, in inputs at 1
  startIdx = startIdx - 1;
  endIdx = endIdx - 1;

  searchTerms = searchTerms.map((s) => replaceSofit(s));

  // UPDATE IN PROGRESS
  store.commit(STORE_ENUMS.MUTATIONS.SHARED.UPDATE_VALUE, {
    stateKey: STORE_ENUMS.STATE_KEYS.DECODE_RESULTS,
    field: "inProgress",
    value: true,
  });

  // DIVIDE WORKER THREADS
  const workers = divideWorkers(
    startIdx,
    endIdx,
    searchTerms,
    minSkip,
    maxSkip,
    matrixLetters
  );
  for (let workerIdx = 0; workerIdx < workers.length; workerIdx++) {
    initSkipAlgorithmWorkerThread({
      ...workers[workerIdx],
      onMessageCallback,
      workerIdx,
      workers,
    });
  }
};

const initSkipAlgorithmWorkerThread = ({
  letter,
  startIdx,
  endIdx,
  searchTerms,
  minSkip,
  maxSkip,
  workerIdx,
  workers,
  onMessageCallback,
  matrixLetters,
}) => {
  if (window.Worker) {
    // CREATE WORKER
    const worker = new Worker("/workers/skipAlgorithm.worker.js", {
      type: "module",
    });
    worker.postMessage(
      JSON.stringify({
        letter,
        startIdx,
        endIdx,
        searchTerms,
        minSkip,
        maxSkip,
        matrixLetters,
      })
    );

    // ON WORKER MESSAGE
    worker.onmessage = (msg) => {
      if (msg.data) {
        const parsedData = JSON.parse(msg.data);
        onMessageCallback(parsedData, workers.length);
      } else {
        worker.terminate();
        workers[workerIdx].complete = true;
        if (workers.every((w) => w.complete)) {
          onMessageCallback(null);
        }
      }
    };
  }
};
