export default {
  ACTION: "action",
  BLUR: "blur",
  CHANGE: "change",
  CLICK: "click",
  CLOSE: "close",
  DELETE: "delete",
  EDIT: "edit",
  GENERATE_MATRIX: "generate_matrix",
  INPUT: "input",
  REMOVE: "remove",
  SAVE: "save",
  SELECT: "select",
  SUBMIT: "submit",
  TOGGLE: "toggle",
};
