<template>
  <div class="search_results">
    <!-- RESULTS LIST -->
    <results-list v-if="results.length" :results="results" />

    <!-- EMPTY STATE -->
    <p v-else class="empty_state">No results</p>
  </div>
</template>

<script>
// ENUMS
import STORE_ENUMS from "@/store/enums";

// HELPERS
import matrixBuilderHelper from "@/helpers/torahDecoder/matrixBuilder";

// COMPONENTS
import ResultsList from "@/components/torahCodeProgram/Results/ResultsList/ResultsList";

export default {
  name: "DecodeResults",
  components: { ResultsList },
  watch: {
    "results.length"(val) {
      if (val > 0 && !this.matrixInView) {
        this.generateMarix();
      }
    },
  },
  computed: {
    results() {
      return (
        this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_RESULTS]?.results || []
      );
    },
    searchTerms() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
        .searchTerms;
    },
    matrixInView() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].show;
    },
  },
  methods: {
    generateMarix() {
      this.selectResult();
      matrixBuilderHelper.generateResultMatrix({
        width: 50,
        height:
          this.results[0].word.length < 10 ? 10 : this.results[0].word.length,
        skip: this.results[0].skip,
        matrixTerm: this.results[0],
        resetResults: false,
      });
    },
    selectResult() {
      this.$store.commit(
        STORE_ENUMS.MUTATIONS.DECODE_RESULTS.SELECT_RESULT,
        this.results[0]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.search_results {
  color: var(--snow-grey);
  height: 100%;
  overflow: hidden;

  .empty_state {
    color: var(--midnight-blue);
    padding: 0 10px;
  }
}
</style>
