<template>
  <ul class="shape_picker">
    <li
      :class="{
        square: true,
        shape: true,
        selected: selected === ENUMS.SHAPES.SQUARE,
      }"
      @[EVENTS.CLICK]="() => $emit(EVENTS.SELECT, ENUMS.SHAPES.SQUARE)"
    />

    <li
      :class="{
        circle: true,
        shape: true,
        selected: selected === ENUMS.SHAPES.CIRCLE,
      }"
      @[EVENTS.CLICK]="() => $emit(EVENTS.SELECT, ENUMS.SHAPES.CIRCLE)"
    />
  </ul>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import ENUMS from "@/enums//enums";

export default {
  name: "ShapePicker",
  props: {
    selected: {
      type: String,
      required: false,
      default: ENUMS.SHAPES.SQUARE,
      validator: (val) => Object.values(ENUMS.SHAPES).includes(val),
    },
  },
  data() {
    return {
      EVENTS,
      ENUMS,
    };
  },
};
</script>

<style lang="scss" scoped>
.shape_picker {
  display: flex;
  padding: 0;
  gap: 1rem;

  .shape {
    height: 30px;
    width: 30px;
    background-color: var(--midnight-blue);
    display: block;

    &.circle {
      border-radius: 50%;
    }

    &.square {
      border-radius: 4px;
    }

    &.selected {
      border: 2px solid var(--salmon-red);
    }
  }
}
</style>
