<template>
  <div class="progress_indicator">
    <span>Decoding...</span>
    <span v-if="percentage < 99.99999 && percentage > 0">
      {{ percentage }} %
    </span>
  </div>
</template>

<script>
import STORE_ENUMS from "@/store/enums";

export default {
  name: "ProgressIndicator",
  computed: {
    percentage() {
      return (
        this.$store.state[
          STORE_ENUMS.STATE_KEYS.DECODE_RESULTS
        ]?.progress.toFixed(2) || 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.progress_indicator {
  width: max-content;

  span {
    color: var(--snow-grey);
    font-size: 16px;
  }
}
</style>
