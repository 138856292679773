import STORE_ENUMS from "@/store/enums";
import STORE_CONFIG from "@/store/config";

const mutations = STORE_ENUMS.MUTATIONS.SHARED;

export default {
  [mutations.UPDATE_VALUE]: (state, { stateKey, field, value }) => {
    state[stateKey][field] = value;
  },
  [mutations.ADD_TO_LIST]: (state, { stateKey, field, item }) => {
    state[stateKey][field].push(item);
  },
  [mutations.REMOVE_FROM_LIST]: (state, { stateKey, field, itemIdx }) => {
    state[stateKey][field].splice(itemIdx, 1);
  },
  [mutations.RESET_STATE]: (state, { stateKey }) => {
    state[stateKey] = { ...STORE_CONFIG.DEFAULT_STATES[stateKey] };
  },
  [mutations.DEFINE_STATE]: (state, { stateKey, newState }) => {
    state[stateKey] = newState;
  },
};
