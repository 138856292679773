<template>
  <div class="search_in_matrix">
    <p v-if="inProgress">Searching...</p>

    <div v-if="!inProgress" class="form">
      <!-- SEARCH INPUT -->
      <div class="word_input_wrapper">
        <base-text-input
          class="matrix_search_term_input"
          id="matrix_search_term_input"
          label="Word"
          :value="searchTermInputValue"
          @[EVENTS.CHANGE]="($event) => (searchTermInputValue = $event)"
        />

        <!-- SUBMIT SEARCH TERM -->
        <base-button
          class="submit_term_btn"
          id="matrix_submit_search_word_btn"
          text="+"
          theme="SECONDARY"
          :disabled="!isWordValid"
          @[EVENTS.CLICK]="addSearchTerm"
        />
      </div>

      <!-- SEARCH BUTTON -->
      <base-button
        class="search_in_matrix_btn"
        id="search_in_matrix_btn"
        text="Search"
        theme="SECONDARY"
        :disabled="!words.length"
        @[EVENTS.CLICK]="searchInMatrix"
      />
    </div>

    <!-- WORD BANK -->
    <word-bank
      v-if="words.length"
      class="word_bank"
      :words="words"
      @[EVENTS.REMOVE]="removeSearchTerm"
    />
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import STORE_ENUMS from "@/store/enums";
import CONFIG from "@/screens/TorahCodeProgram/config";

// HELPERS
import { useSkipAlgorithm } from "@/helpers/torahDecoder/skipAlgorithm";

// COMPONENTS
import WordBank from "@/components/shared/WordBank/WordBank";
import BaseTextInput from "@/components/shared/BaseTextInput/BaseTextInput";
import BaseButton from "@/components/shared/BaseButton/BaseButton";

export default {
  name: "SearchInMatrix",
  props: {},
  components: { BaseTextInput, BaseButton, WordBank },
  data() {
    return {
      EVENTS,
      searchTermInputValue: "",
      words: [],
      inProgress: false,
    };
  },
  computed: {
    isWordValid() {
      const isHebrew = this.searchTermInputValue
        .split("")
        .every((l) => CONFIG.HEBREW_LETTERS.includes(l));

      return this.searchTermInputValue.length >= 2 && isHebrew;
    },
  },
  methods: {
    addSearchTerm() {
      this.words.push(this.searchTermInputValue.trim());
      this.searchTermInputValue = "";
    },
    removeSearchTerm(term) {
      const termIdx = this.words.findIndex((item) => item === term);
      if (termIdx !== -1) {
        this.words.splice(termIdx, 1);
      }
    },
    async searchInMatrix() {
      this.inProgress = true;
      this.$store.commit(
        STORE_ENUMS.MUTATIONS.MATRIX.UPDATE_MATRIX_SEARCH_PROGRESS,
        true
      );
      await useSkipAlgorithm({
        searchTerms: this.words,
        matrixLetters:
          this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].matrixLetters,
        onMessageCallback: (parsedData) => {
          // SEARCH DONE
          if (parsedData === null) {
            this.inProgress = false;
            this.$store.commit(
              STORE_ENUMS.MUTATIONS.MATRIX.UPDATE_MATRIX_SEARCH_PROGRESS,
              false
            );
            return;
          } else {
            // UPDATE RESULTS
            this.$store.commit(
              STORE_ENUMS.MUTATIONS.MATRIX.ADD_MATRIX_RESULTS,
              parsedData
            );
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  gap: 1rem;
  align-items: flex-end;

  .word_input_wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
  }
}
.matrix_search_term_input {
  max-width: 300px;
}

.word_bank {
  margin-top: 10px;

  ::v-deep .word {
    background-color: var(--dusk-blue);
  }
}
</style>
