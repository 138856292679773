<template>
  <div class="matrix_results">
    <!-- RESULTS LIST -->
    <results-list v-if="matrixResults.length" :results="matrixResults" />

    <!-- EMPTY STATE -->
    <p v-else class="empty_state">No matrix results</p>
  </div>
</template>

<script>
// ENUMS
import STORE_ENUMS from "@/store/enums";

// COMPONENTS
import ResultsList from "@/components/torahCodeProgram/Results/ResultsList/ResultsList";

export default {
  name: "MatrixResults",
  components: { ResultsList },
  data() {
    return {};
  },
  computed: {
    matrixResults() {
      return (
        this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX]?.matrixResults || []
      );
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.empty_state {
  color: var(--midnight-blue);
  padding: 0 10px;
}
</style>
