<template>
  <div class="search_form">
    <div class="word_search_container section">
      <div class="terms_input_container">
        <!-- SEARCH INPUT -->
        <base-text-input
          id="search_term_input"
          :class="{
            error: showNoWordsErr,
          }"
          label="Search Word"
          :value="searchTermInputValue"
          @[EVENTS.CHANGE]="($event) => (searchTermInputValue = $event)"
        />

        <!-- SUBMIT SEARCH TERM -->
        <base-button
          class="submit_term_btn"
          id="submit_search_word_btn"
          text="+"
          :disabled="!isWordValid"
          @[EVENTS.CLICK]="addSearchTerm"
        />
      </div>

      <span v-if="showNoWordsErr" class="error_msg">Add words to search.</span>

      <!-- WORD BANK -->
      <word-bank
        v-if="searchTerms.length"
        :words="searchTerms"
        @[EVENTS.REMOVE]="removeSearchTerm"
      />
    </div>
    <base-form :fields="fields" btnText="Search" @[EVENTS.SUBMIT]="search" />
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import STORE_ENUMS from "@/store/enums";
import CONFIG from "@/screens/TorahCodeProgram/config";

// MODELS
import { FormField } from "@/models/FormField";

// HELPERS
import { useSkipAlgorithm } from "@/helpers/torahDecoder/skipAlgorithm";

// COMPONENTS
import WordBank from "@/components/shared/WordBank/WordBank";
import BaseTextInput from "@/components/shared/BaseTextInput/BaseTextInput";
import BaseButton from "@/components/shared/BaseButton/BaseButton";
import BaseForm from "@/components/shared/BaseForm/BaseForm";

export default {
  name: "SearchForm",
  components: { WordBank, BaseTextInput, BaseButton, BaseForm },
  data: () => {
    return {
      EVENTS,
      searchTermInputValue: "",
      showNoWordsErr: false,
    };
  },
  computed: {
    searchTerms() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
        .searchTerms;
    },
    fields() {
      return [
        new FormField({
          key: "minSkip",
          label: "Min Skip",
          value:
            this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
              .minSkip,
          type: "number",
          validators: [
            {
              validation: (value) => value > 0,
              errorMessage: "Value must be greater than 0.",
            },
            {
              validation: (value, form) => value <= form.maxSkip.value,
              errorMessage: "Value must be less than or equal to Max Skip.",
            },
          ],
        }),
        new FormField({
          key: "maxSkip",
          label: "Max Skip",
          value:
            this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
              .maxSkip,
          type: "number",
          validators: [
            {
              validation: (value) => value > 0,
              errorMessage: "Value must be greater than 0.",
            },
            {
              validation: (value) => value <= 152402,
              errorMessage: "Value must be less than or equal to 152,402.",
            },
            {
              validation: (value, form) => value >= form.minSkip.value,
              errorMessage: "Value must be greater than or equal to Min Skip.",
            },
          ],
        }),
        new FormField({
          key: "startIdx",
          label: "Start Range Index",
          value:
            this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
              .startIdx,
          type: "number",
          validators: [
            {
              validation: (value) => value > 0,
              errorMessage: "Value must be greater than 0.",
            },
            {
              validation: (value, form) => value <= form.endIdx.value,
              errorMessage: "Value must be less than or equal to End Index.",
            },
          ],
        }),
        new FormField({
          key: "endIdx",
          label: "End Range Index",
          value:
            this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
              .endIdx,
          type: "number",
          validators: [
            {
              validation: (value) => value > 0,
              errorMessage: "Value must be greater than 0.",
            },
            {
              validation: (value) => value <= 304805,
              errorMessage: "Value must be less than or equal to 304,805.",
            },
            {
              validation: (value, form) => value >= form.startIdx.value,
              errorMessage:
                "Value must be greater than or equal to Start Index.",
            },
          ],
        }),
      ];
    },
    isWordValid() {
      const isHebrew = this.searchTermInputValue
        .split("")
        .every((l) => CONFIG.HEBREW_LETTERS.includes(l));

      return this.searchTermInputValue.length >= 2 && isHebrew;
    },
  },
  methods: {
    addSearchTerm() {
      if (!this.searchTerms.includes(this.searchTermInputValue)) {
        this.$store.commit(STORE_ENUMS.MUTATIONS.SHARED.ADD_TO_LIST, {
          stateKey: STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS,
          field: "searchTerms",
          item: this.searchTermInputValue,
        });
        this.showNoWordsErr = false;
      }
      this.clearSearchTermInput();
    },
    clearSearchTermInput() {
      this.searchTermInputValue = "";
    },
    removeSearchTerm(term) {
      const termIdx = this.searchTerms.findIndex((item) => item === term);
      if (termIdx !== -1) {
        this.$store.commit(STORE_ENUMS.MUTATIONS.SHARED.REMOVE_FROM_LIST, {
          stateKey: STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS,
          field: "searchTerms",
          itemIdx: termIdx,
        });
      }
    },
    async search(form) {
      if (!this.searchTerms.length) {
        this.showNoWordsErr = true;
        return;
      }
      form.searchTerms = this.searchTerms;
      this.updateStoreForm(form);
      await this.initDecode(form);
    },
    updateStoreForm(form) {
      this.$store.commit(STORE_ENUMS.MUTATIONS.SHARED.DEFINE_STATE, {
        stateKey: STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS,
        newState: form,
      });
    },
    async initDecode(form) {
      await useSkipAlgorithm({
        ...form,
        onMessageCallback: (parsedData, workersLength) => {
          // SEARCH DONE
          if (parsedData === null) {
            this.$store.commit(
              STORE_ENUMS.MUTATIONS.DECODE_RESULTS.UPDATE_PROGRESS,
              0
            );
            return;
          }

          // UPDATE PROGRESS
          if (typeof parsedData === "number") {
            this.$store.commit(
              STORE_ENUMS.MUTATIONS.DECODE_RESULTS.UPDATE_PROGRESS,
              parsedData / workersLength
            );
          } else {
            // UPDATE RESULTS
            this.$store.commit(
              STORE_ENUMS.MUTATIONS.DECODE_RESULTS.ADD_RESULTS,
              parsedData
            );
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search_form {
  display: grid;
  gap: 20px;
  color: var(--snow-grey);
  margin-bottom: 20px;

  .error ::v-deep input {
    outline: 1px solid var(--salmon-red);
  }

  .section {
    display: grid;
    gap: 10px;

    .terms_input_container {
      display: flex;
      gap: 10px;
      align-items: flex-end;
    }

    .error_msg {
      color: var(--salmon-red);
    }
  }
}
</style>
