var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    result_card: true,
    selected: _vm.selected,
  }},[_c('actions-menu',{staticClass:"actions_menu",attrs:{"id":_vm.result.id,"actions":_vm.actions},on:_vm._d({},[_vm.EVENTS.GENERATE_MATRIX,_vm.generateResultMatrix,_vm.EVENTS.DELETE,function($event){return _vm.$emit(_vm.EVENTS.DELETE)},_vm.EVENTS.EDIT,_vm.toggleEditStyle])}),_c('div',{staticClass:"result_data",on:_vm._d({},[_vm.EVENTS.CLICK,function($event){return _vm.$emit(_vm.EVENTS.SELECT)}])},[_c('div',{staticClass:"selected_banner",style:({
        backgroundColor: _vm.selected
          ? _vm.result.style.backgroundColor === _vm.COLORS.WHITE
            ? _vm.result.style.color
            : _vm.result.style.backgroundColor
          : _vm.COLORS.WHITE,
      })}),_c('div',{staticClass:"data"},[_c('p',{class:{ word: true, selected: _vm.selected },style:({
          backgroundColor: _vm.selected
            ? _vm.result.style.backgroundColor
            : _vm.COLORS.SNOW_GREY,
          color: _vm.selected ? _vm.result.style.color : _vm.COLORS.MIDNIGHT_BLUE,
          border:
            _vm.selected && _vm.result.style.borderColor
              ? '2px solid ' + _vm.result.style.borderColor
              : '2px solid transparent',
        })},[_vm._v(" "+_vm._s(_vm.result.word)+" ")]),_c('p',[_vm._v("Skip: "+_vm._s(_vm.result.skip.toLocaleString()))]),_c('p',{staticClass:"index_list_title"},[_vm._v(" Indexes: "+_vm._s(_vm.result.letterIndexes[0].toLocaleString())+" - "+_vm._s(_vm.result.letterIndexes[ _vm.result.letterIndexes.length - 1 ].toLocaleString())+" ")])])]),(_vm.showEditStyleForm)?_c('screen-popup',{staticClass:"result_style_popup",attrs:{"overlay":""},on:_vm._d({},[_vm.EVENTS.CLOSE,_vm.toggleEditStyle]),scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('result-style-form',{attrs:{"result":_vm.result},on:_vm._d({},[_vm.EVENTS.SAVE,_vm.toggleEditStyle])})]},proxy:true}],null,false,2618429249)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }