import Vue from "vue";

// Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheck,
  faEllipsisVertical,
  faBars,
  faPalette,
  faMagnifyingGlass,
  faAngleLeft,
  faAnglesLeft,
  faAngleRight,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCheck,
  faEllipsisVertical,
  faBars,
  faPalette,
  faMagnifyingGlass,
  faAngleLeft,
  faAnglesLeft,
  faAngleRight,
  faAnglesRight
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
