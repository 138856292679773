export class FormField {
  constructor(objInit = {}) {
    this.key = objInit.key;
    this.label = objInit.label;
    this.value = objInit.value;
    this.type = objInit.type;
    this.validators = objInit.validators.map((v) => new FieldValidator(v));
  }
}

class FieldValidator {
  constructor(objInit = {}) {
    this.validation = objInit.validation;
    this.errorMessage = objInit.errorMessage;
  }
}
