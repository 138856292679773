import Vue from "vue";
import Vuex from "vuex";
import mutations from "@/store/mutations";
import STORE_ENUMS from "./enums";
import STORE_CONFIG from "./config";

Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      [STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]: {
        ...STORE_CONFIG.DEFAULT_STATES[
          STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS
        ],
      },
      [STORE_ENUMS.STATE_KEYS.DECODE_RESULTS]: {
        ...STORE_CONFIG.DEFAULT_STATES[STORE_ENUMS.STATE_KEYS.DECODE_RESULTS],
      },
      [STORE_ENUMS.STATE_KEYS.MATRIX]: {
        ...STORE_CONFIG.DEFAULT_STATES[STORE_ENUMS.STATE_KEYS.MATRIX],
      },
    };
  },
  mutations: mutations,
});
