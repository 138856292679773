<template>
  <div class="base_tabs" :style="tabsStyle">
    <div class="tabs_nav" role="tablist">
      <button
        v-for="(tab, idx) in tabs"
        :class="{
          tab_nav_btn: true,
          selected: selectedTab === idx,
        }"
        role="tab"
        :aria-controls="`panel_${tab.id}`"
        :aria-selected="selectedTab === idx"
        :key="tab.id"
        :id="`tab_${tab.id}`"
        @[EVENTS.CLICK]="() => $emit(EVENTS.SELECT, idx)"
      >
        {{ tab.title }}
      </button>
    </div>

    <slot
      name="tabContent"
      :id="`panel_${tabs[selectedTab].id}`"
      role="tabpanel"
      :aria-labelledby="`tab_${tabs[selectedTab].id}`"
    >
      This is tab content
    </slot>
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import COLORS from "@/styles/colors";

export default {
  name: "BaseTabs",
  components: {},
  props: {
    tabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedTab: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      EVENTS,
    };
  },
  computed: {
    tabsStyle() {
      return {
        "--nav-background": COLORS.AQUA_BLUE,
        "--active-tab-bg": COLORS.SNOW_GREY,
      };
    },
  },
  methods: {},
  mounted() {
    const tabs = document.getElementsByClassName("tab_nav_btn");
    const tabList = document.getElementsByClassName("tabs_nav")[0];

    // Enable arrow navigation between tabs in the tab list
    let tabFocus = 0;

    tabList.addEventListener("keydown", (e) => {
      // Move right
      if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
        tabs[tabFocus].setAttribute("tabindex", -1);
        if (e.key === "ArrowRight") {
          tabFocus++;
          // If we're at the end, go to the start
          if (tabFocus >= tabs.length) {
            tabFocus = 0;
          }
          // Move left
        } else if (e.key === "ArrowLeft") {
          tabFocus--;
          // If we're at the start, move to the end
          if (tabFocus < 0) {
            tabFocus = tabs.length - 1;
          }
        }

        tabs[tabFocus].setAttribute("tabindex", 0);
        tabs[tabFocus].focus();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.base_tabs {
  .tabs_nav {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .tab_nav_btn {
      flex: 1;
      background-color: var(--nav-background);
      color: var(--white);
      border: none;
      cursor: pointer;
      font-size: 16px;
      padding: 10px;
      border-right: 1px solid var(--snow-grey);
      border-left: 1px solid var(--snow-grey);
      font-weight: bold;

      &:first-of-type {
        border-top-left-radius: 4px;
        border-left: none;
      }

      &:last-of-type {
        border-top-right-radius: 4px;
        border-right: none;
      }

      &.selected {
        background-color: var(--active-tab-bg);
        color: var(--nav-background);
      }
    }
  }
}
</style>
