import { Result } from "@/models/ResultModel";
import STORE_ENUMS from "@/store/enums";
import COLORS from "@/styles/colors";

const stateKey = STORE_ENUMS.STATE_KEYS.DECODE_RESULTS;
const mutations = STORE_ENUMS.MUTATIONS.DECODE_RESULTS;

export default {
  [mutations.ADD_RESULTS]: (state, newResuls) => {
    state[stateKey].results = state[stateKey].results.concat(
      newResuls.map((r) => new Result(r))
    );
  },
  [mutations.SELECT_RESULT]: (state, item) => {
    if (item.style.backgroundColor === COLORS.AQUA_BLUE) {
      item.updateStyle("color", COLORS.WHITE);
    }
    state[stateKey].selected.push(item);
  },
  [mutations.DESELECT_RESULT]: (state, idx) => {
    if (idx || idx === 0) {
      state[stateKey].selected.splice(idx, 1);
    }
  },
  [mutations.UPDATE_PROGRESS]: (state, value) => {
    const currentValue = state[stateKey].progress;
    let newValue;
    if (value === 0) {
      newValue = null;
    } else {
      const progress = currentValue + value;
      if (progress >= 100) {
        newValue = null;
      } else {
        newValue = currentValue + value;
      }
    }
    state[stateKey].progress = newValue;
  },
  [mutations.ADD_CUSTOM_RESULT]: (state) => {
    const item = new Result({
      ...state[STORE_ENUMS.STATE_KEYS.MATRIX].currentCustomResult,
    });
    item.updateStyle("color", COLORS.WHITE);
    state[stateKey].custom.push(item);
    state[STORE_ENUMS.STATE_KEYS.MATRIX].currentCustomResult = null;
    state[stateKey].selected.push(item);
  },
  [mutations.REMOVE_CUSTOM_RESULT]: (state, idx) => {
    if (idx || idx === 0) {
      const selectedId = state[stateKey].custom[idx].id;
      const selectedIdx = state[stateKey].selected.findIndex(
        (i) => i.id === selectedId
      );
      if (selectedIdx >= 0) {
        state[stateKey].selected.splice(selectedIdx, 1);
      }
      state[stateKey].custom.splice(idx, 1);
    }
  },
  [mutations.UPDATE_DISPLAYED_RESULTS]: (state) => {
    if (
      state[stateKey].resultsDisplayed.length !== state[stateKey].results.length
    ) {
      state[stateKey].resultsDisplayed = [...state[stateKey].results];
    }
  },
  [mutations.CLEAR_RESULS_TIMER]: (state) => {
    clearInterval(state[stateKey].displayedResultsTimer);
    state[stateKey].displayedResultsTimer = null;

    if (
      state[stateKey].resultsDisplayed.length !== state[stateKey].results.length
    ) {
      state[stateKey].resultsDisplayed = [...state[stateKey].results];
    }
  },
};
