<template>
  <div class="search_in_progress">
    <!-- SEARCH TERMS -->
    <div class="search_terms">
      <strong>Search Terms</strong>
      <ul class="terms_list">
        <li v-for="term in searchTerms" class="term" :key="term">
          {{ term }}
        </li>
      </ul>
    </div>

    |

    <!-- INDEX RANGE -->
    <strong>Index Range</strong
    ><span>
      {{ startIdx.toLocaleString() }} - {{ endIdx.toLocaleString() }}</span
    >

    |

    <!-- SKIP RANGE -->
    <strong>Skip Range</strong
    ><span>
      {{ minSkip.toLocaleString() }} - {{ maxSkip.toLocaleString() }}</span
    >
  </div>
</template>

<script>
// ENUMS
import STORE_ENUMS from "@/store/enums";

export default {
  name: "SearchInProgress",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    searchTerms() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
        .searchTerms;
    },
    minSkip() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
        .minSkip;
    },
    maxSkip() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
        .maxSkip;
    },
    startIdx() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
        .startIdx;
    },
    endIdx() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]
        .endIdx;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.search_in_progress {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: var(--snow-grey);
  min-width: max-content;
  font-size: 18px;

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }

  .search_terms {
    display: flex;
    gap: 1rem;
    align-items: center;

    .terms_list {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      list-style: none;
      padding: 0;

      .term {
        font-family: var(--font-rubik);
        &:not(:last-of-type)::after {
          content: ",";
        }
      }
    }
  }
}
</style>
