<template>
  <button
    class="base_button"
    :title="title"
    :id="id"
    :style="buttonStyles"
    :disabled="disabled"
    @[EVENTS.CLICK]="handleClick"
  >
    <base-icon
      v-if="prefixIcon"
      :icon="prefixIcon"
      :color="buttonStyles['--color']"
    />
    <span v-if="text">{{ text }}</span>
  </button>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import THEME from "./theme";

// COMPONENTS
import BaseIcon from "@/components/shared/BaseIcon/BaseIcon";

export default {
  name: "BaseButton",
  components: { BaseIcon },
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    theme: {
      type: String,
      required: false,
      default: "PRIMARY",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    prefixIcon: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => {
    return {
      EVENTS,
    };
  },
  computed: {
    buttonStyles() {
      return {
        "--background-color": THEME[this.theme].BACKGROUND,
        "--color": THEME[this.theme].COLOR,
        "--hover-background-color": THEME[this.theme].HOVER.BACKGROUND,
        "--hover-color": THEME[this.theme].HOVER.COLOR,
        "--border": THEME[this.theme].BORDER || "none",
      };
    },
  },
  methods: {
    handleClick() {
      this.$emit(EVENTS.CLICK);
    },
  },
};
</script>

<style lang="scss" scoped>
.base_button {
  display: block;
  padding: 10px 15px;
  border-radius: 4px;
  width: max-content;
  height: max-content;
  background-color: var(--background-color);
  color: var(--color);
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  border: var(--border);

  &:hover {
    background-color: var(--hover-background-color);
    color: var(--hover-color);
  }

  &:disabled {
    background-color: var(--snow-grey);
    color: var(--midnight-blue);
    cursor: default;
  }
}
</style>
