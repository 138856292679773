<template>
  <div class="base_form">
    <div v-for="field in fields" class="field" :key="field.key">
      <base-text-input
        v-if="field.type === 'text'"
        :class="{
          error: !!form[field.key]?.error,
        }"
        :id="field.key + '_text_input'"
        :label="field.label"
        :value="form[field.key]?.value || ''"
        @[EVENTS.CHANGE]="($event) => handleChange($event, field.key)"
      />

      <base-number-input
        v-if="field.type === 'number'"
        :class="{
          error: !!form[field.key]?.error,
        }"
        :id="field.key + '_number_input'"
        :value="form[field.key]?.value || 1"
        :label="field.label"
        @[EVENTS.CHANGE]="($event) => handleChange($event, field.key)"
      />

      <span v-if="!!form[field.key]?.error" class="error_msg">{{
        form[field.key].error
      }}</span>
    </div>

    <base-button
      id="submit_btn"
      :text="btnText"
      @[EVENTS.CLICK]="validateFields"
    />
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";

// COMPONENTS
import BaseTextInput from "@/components/shared/BaseTextInput/BaseTextInput";
import BaseNumberInput from "@/components/shared/BaseNumberInput/BaseNumberInput";
import BaseButton from "@/components/shared/BaseButton/BaseButton";

export default {
  name: "BaseForm",
  components: { BaseTextInput, BaseNumberInput, BaseButton },
  props: {
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    btnText: {
      type: String,
      required: false,
      default: "Submit",
    },
  },
  data() {
    return {
      EVENTS,
      form: {},
    };
  },
  watch: {
    fields() {
      this.populateFields();
    },
  },
  methods: {
    populateFields() {
      const form = {};
      this.fields.forEach((field) => {
        form[field.key] = {
          value: field.value || "",
          error: null,
        };
      });
      this.form = form;
    },
    handleChange(val, field) {
      this.form[field].value = val;
      if (this.form[field].error) {
        this.form[field].error = null;
      }
    },
    validateFields() {
      let validForm = true;
      this.fields.forEach((field) => {
        let validatedField = true;
        field.validators.forEach((validator) => {
          if (validatedField) {
            const currentValidation = validator.validation(
              this.form[field.key].value,
              this.form
            );
            if (!currentValidation) {
              this.form[field.key].error = validator.errorMessage;
              validForm = false;
              validatedField = false;
              return;
            }
          }
        });
        if (validatedField) {
          this.form[field.key].error = null;
        }
      });
      if (validForm) {
        this.submit();
      }
    },
    submit() {
      const formattedForm = {};
      Object.keys(this.form).forEach((k) => {
        formattedForm[k] = this.form[k].value;
      });
      this.$emit(EVENTS.SUBMIT, formattedForm);
    },
  },
  created() {
    this.populateFields();
  },
};
</script>

<style lang="scss" scoped>
.base_form {
  display: grid;
  gap: 20px;

  .field {
    display: grid;
    gap: 0.5rem;

    ::v-deep input {
      width: 100%;
    }

    .error ::v-deep input {
      outline: 1px solid var(--salmon-red);
    }

    .error_msg {
      color: var(--salmon-red);
    }
  }
}
</style>
