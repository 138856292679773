import { TORAH } from "../../../public/data/torah";

export default {
  TORAH_INDEXES: {
    MIN: 1,
    MAX: TORAH.length,
  },
  MATRIX: {
    DEFAULT_WIDTH: 50,
    DEFAULT_HEIGHT: 10,
    COLORS: [
      "#e34544",
      "#ffa500",
      "#f2d034",
      "#40986a",
      "#4672c0",
      "#8041b2",
      "#ffacba",
      "#9d7a5b",
      "#FFFFFF",
    ],
  },
  HEBREW_LETTERS: [
    "א",
    "ב",
    "ג",
    "ד",
    "ה",
    "ו",
    "ז",
    "ח",
    "ט",
    "י",
    "כ",
    "ך",
    "ל",
    "מ",
    "ם",
    "נ",
    "ן",
    "ס",
    "ע",
    "פ",
    "ף",
    "צ",
    "ץ",
    "ק",
    "ר",
    "ש",
    "ת",
  ],
};
