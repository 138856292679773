<template>
  <div class="matrix_toolbar">
    <!-- HIGHLIGHTER TOOL -->
    <!-- <base-button
      id="matrix_toolbar_paint"
      :prefixIcon="ICONS.PALETTE"
      @[EVENTS.CLICK]="togglePaintTool"
    /> -->

    <color-picker
      :colors="CONFIG.MATRIX.COLORS"
      :selected="selectedColor"
      @[EVENTS.SELECT]="selectColor"
    />

    <div v-if="!!currentCustomResult" class="word_actions">
      <base-button
        id="matrix_view_add_result_btn"
        text="Add Word"
        @[EVENTS.CLICK]="addCustomResult"
      />

      <base-button
        id="matrix_toolbar_undo_btn"
        text="Undo"
        @[EVENTS.CLICK]="undoChanges"
      />
    </div>
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import ICONS from "@/enums/icons";
import STORE_ENUMS from "@/store/enums";

// CONFIG
import CONFIG from "@/screens/TorahCodeProgram/config";

// COMPONENTS
import BaseButton from "@/components/shared/BaseButton/BaseButton";
import ColorPicker from "../../../shared/ColorPicker/ColorPicker";

export default {
  name: "MatrixToolBar",
  components: { BaseButton, ColorPicker },
  props: {
    paintActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedColor: {
      type: String,
      required: false,
      default: "",
    },
    showUndo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      EVENTS,
      ICONS,
      CONFIG,
    };
  },
  computed: {
    currentCustomResult() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX]
        .currentCustomResult;
    },
  },
  methods: {
    togglePaintTool() {
      this.$emit(EVENTS.ACTION, {
        action: EVENTS.UPDATE,
        field: "paint",
        value: !this.paintActive,
      });
      this.selectColor(null);
    },
    selectColor(val) {
      this.$emit(EVENTS.ACTION, {
        action: EVENTS.UPDATE,
        field: "selectedColor",
        value: val,
      });
    },
    addCustomResult() {
      this.$store.commit(
        STORE_ENUMS.MUTATIONS.DECODE_RESULTS.ADD_CUSTOM_RESULT
      );
    },
    undoChanges() {
      this.$store.commit(STORE_ENUMS.MUTATIONS.SHARED.UPDATE_VALUE, {
        stateKey: STORE_ENUMS.STATE_KEYS.MATRIX,
        field: "currentCustomResult",
        value: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.word_actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}
</style>
