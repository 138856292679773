<template>
  <div id="app">
    <torah-code-program />
  </div>
</template>

<script>
import TorahCodeProgram from "@/screens/TorahCodeProgram/TorahCodeProgram";

export default {
  name: "App",
  components: {
    TorahCodeProgram,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: var(--midnight-blue);
  font-family: var(--font-nunito);
}
</style>
