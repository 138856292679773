<template>
  <div class="custom_results">
    <!-- RESULTS LIST -->
    <results-list
      v-if="customResults.length"
      :results="customResults"
      canDelete
    />

    <!-- EMPTY STATE -->
    <p v-else class="empty_state">No custom results</p>
  </div>
</template>

<script>
// ENUMS
import STORE_ENUMS from "@/store/enums";

// COMPONENTS
import ResultsList from "@/components/torahCodeProgram/Results/ResultsList/ResultsList";

export default {
  name: "CustomResults",
  components: { ResultsList },
  data() {
    return {};
  },
  computed: {
    customResults() {
      return (
        this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_RESULTS]?.custom || []
      );
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.empty_state {
  color: var(--midnight-blue);
  padding: 0 10px;
}
</style>
