<template>
  <div class="results_list">
    <!-- TITLE -->
    <p v-if="title" class="title">{{ title }}</p>

    <!-- LIST -->
    <paginated-list class="paginated_list" :items="results">
      <template v-slot="{ item, idx }">
        <result-card
          :result="item"
          :selected="!!selected.find((result) => result.id === item.id)"
          :canDelete="canDelete"
          @[EVENTS.SELECT]="onSelectResult(item)"
          @[EVENTS.DELETE]="deleteResult(idx)"
        />
      </template>
    </paginated-list>
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import STORE_ENUMS from "@/store/enums";

// COMPONENTS
import ResultCard from "@/components/torahCodeProgram/Results/ResultCard/ResultCard";
import PaginatedList from "@/components/shared/PaginatedList/PaginatedList";

export default {
  name: "ResultsList",
  components: { ResultCard, PaginatedList },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    results: {
      type: Array,
      required: false,
      default: () => [],
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      EVENTS,
    };
  },
  computed: {
    selected() {
      return (
        this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_RESULTS]?.selected || []
      );
    },
  },
  methods: {
    onSelectResult(item) {
      const selectedItemIdx = this.selected.findIndex((i) => i.id === item.id);
      if (selectedItemIdx !== -1) {
        this.deselectResult(selectedItemIdx);
      } else {
        this.selectResult(item);
      }
    },
    selectResult(item) {
      this.$store.commit(
        STORE_ENUMS.MUTATIONS.DECODE_RESULTS.SELECT_RESULT,
        item
      );
    },
    deselectResult(idx) {
      this.$store.commit(
        STORE_ENUMS.MUTATIONS.DECODE_RESULTS.DESELECT_RESULT,
        idx
      );
    },
    deleteResult(idx) {
      this.$store.commit(
        STORE_ENUMS.MUTATIONS.DECODE_RESULTS.REMOVE_CUSTOM_RESULT,
        idx
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.results_list {
  height: 100%;
  overflow: hidden;

  ::v-deep .result_card {
    &:hover {
      background-color: #dce6f1;
    }

    &:not(.selected) {
      &:hover {
        .selected_banner {
          background-color: #dce6f1 !important;
        }
      }
    }
  }

  .paginated_list {
    height: 100%;
  }
}
</style>
