var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matrix_view"},[_c('div',{staticClass:"top_section"},[_c('div',{staticClass:"matrix_actions"},[_c('div',{staticClass:"section"},[_c('matrix-config'),_c('base-button',{class:{
            active: _vm.showSearchInMatrix,
          },attrs:{"id":"show_search_in_matrix_btn","title":"Search in Matrix","prefixIcon":_vm.ICONS.SEARCH,"theme":"SECONDARY"},on:_vm._d({},[_vm.EVENTS.CLICK,_vm.toggleSearchInMatrix])}),_c('base-button',{class:{
            active: _vm.showToolbar,
          },attrs:{"id":"show_toolbar_matrix_btn","title":"Show Highlight Words Tool","theme":"SECONDARY","prefixIcon":_vm.ICONS.PALETTE},on:_vm._d({},[_vm.EVENTS.CLICK,_vm.toggleToolbar])})],1),_c('base-button',{staticClass:"reset_btn",attrs:{"id":"reset_matrix_btn","text":"Reset","theme":"SECONDARY_INVERT"},on:_vm._d({},[_vm.EVENTS.CLICK,_vm.resetMatrix])})],1),_c('div',[(_vm.showSearchInMatrix || _vm.showToolbar)?_c('p',{staticClass:"form_title"},[_vm._v(" "+_vm._s(_vm.showSearchInMatrix ? "Search In Matrix" : "Highlight Word")+" ")]):_vm._e(),(_vm.showSearchInMatrix)?_c('search-in-matrix'):_vm._e(),(_vm.showToolbar)?_c('matrix-tool-bar',{attrs:{"paintActive":true,"selectedColor":_vm.selectedColor},on:_vm._d({},[_vm.EVENTS.ACTION,_vm.updateTools])}):_vm._e()],1)]),_c('div',{staticClass:"custom_results"},_vm._l((_vm.selectedResults),function(result){return _c('p',{key:result.id,staticClass:"result",style:({
        backgroundColor: result.style.backgroundColor,
        color: result.style.color,
        border: result.style.borderColor
          ? '2px solid ' + result.style.borderColor
          : '2px solid transparent',
      })},[_vm._v(" "+_vm._s(result.word)+" "),(result.skip)?_c('span',[_vm._v(" -"+_vm._s(result.skip.toLocaleString())+" ")]):_vm._e()])}),0),_c('div',{ref:"matrixSection",staticClass:"matrix_section"},[_c('torah-matrix')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }