<template>
  <div class="result_style_form">
    <div class="word_wrapper">
      <p class="word">
        <span
          v-for="(letter, idx) in result.word"
          class="letter"
          :key="letter + idx"
          :style="{
            backgroundColor: bgColor,
            color: color,
            border: borderColor
              ? `2px solid ${borderColor}`
              : '2px solid transparent',
            borderRadius: shape === ENUMS.SHAPES.SQUARE ? '4px' : '50%',
          }"
          >{{ letter }}</span
        >
      </p>
    </div>

    <div class="color section">
      <strong>Letter Color</strong>
      <color-picker
        :colors="CONFIG.MATRIX.COLORS"
        :selected="color"
        @[EVENTS.SELECT]="($event) => (color = $event)"
      />
    </div>

    <div class="background_color section">
      <strong>Background Color</strong>
      <color-picker
        :colors="CONFIG.MATRIX.COLORS"
        :selected="bgColor"
        @[EVENTS.SELECT]="($event) => (bgColor = $event)"
      />
    </div>

    <div class="border_color section">
      <strong>Border Color</strong>
      <color-picker
        :colors="CONFIG.MATRIX.COLORS"
        :selected="borderColor"
        @[EVENTS.SELECT]="($event) => (borderColor = $event)"
      />
    </div>

    <div class="shape section">
      <strong>Shape</strong>
      <shape-picker
        :selected="shape"
        @[EVENTS.SELECT]="($event) => (shape = $event)"
      />
    </div>

    <base-button
      id="result_style_form_submit"
      text="Save"
      @[EVENTS.CLICK]="saveStyle"
    />
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import ENUMS from "@/enums/enums";

// CONFIG
import CONFIG from "@/screens/TorahCodeProgram/config";

// COMPONENTS
import ColorPicker from "@/components/shared/ColorPicker/ColorPicker";
import BaseButton from "@/components/shared/BaseButton/BaseButton";
import ShapePicker from "@/components/shared/ShapePicker/ShapePicker";

export default {
  name: "ResultStyleForm",
  components: { ColorPicker, BaseButton, ShapePicker },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      EVENTS,
      ENUMS,
      CONFIG,
      bgColor: "",
      color: "",
      borderColor: "",
      shape: "",
    };
  },
  methods: {
    saveStyle() {
      this.result.updateStyle("backgroundColor", this.bgColor);
      this.result.updateStyle("color", this.color);
      this.result.updateStyle("borderColor", this.borderColor);
      this.result.updateStyle("shape", this.shape);

      this.$emit(EVENTS.SAVE);
    },
    populateFields() {
      this.bgColor = this.result.style.backgroundColor;
      this.color = this.result.style.color;
      this.borderColor = this.result.style.borderColor;
      this.shape = this.result.style.shape;
    },
  },
  created() {
    this.populateFields();
  },
};
</script>

<style lang="scss" scoped>
.result_style_form {
  display: grid;
  gap: 2rem;
  margin-top: 1rem;

  .word_wrapper {
    display: flex;
    justify-content: flex-end;

    .word {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      direction: rtl;

      .letter {
        width: 22px;
        font-family: var(--font-rubik);
        text-align: center;
      }
    }
  }

  .section {
    display: grid;
    gap: 0.5rem;
  }
}
</style>
