<template>
  <side-panel-layout class="sidepanel_layout" title="Torah Code" topBar>
    <!-- TOP BAR -->
    <template #topBarContent>
      <div class="logo_progress">
        <!-- LOGO -->
        <h1 v-if="!decodeInProgress" class="logo">Torah Codes</h1>

        <!-- PROGRESS INDICATOR -->
        <progress-indicator v-if="decodeInProgress" />
      </div>

      <!-- SEARCH DETAILS -->
      <div class="search_details_container">
        <search-in-progress v-if="decodeInProgress || showResults" />
      </div>
    </template>

    <!-- SIDE PANEL -->
    <template #sidePanelContent>
      <div class="sidepanel">
        <!-- SEARCH FORM -->
        <search-form v-if="!showResults" />

        <!-- TABS -->
        <base-tabs
          v-if="showResults"
          class="tabs"
          :tabs="resultsTabs"
          :selectedTab="selectedResultsTab"
          @[EVENTS.SELECT]="($event) => (selectedResultsTab = $event)"
        >
          <template #tabContent>
            <div class="results">
              <!-- SEARCH RESULTS -->
              <decode-results
                v-if="
                  selectedResultsTab ===
                  resultsTabs.findIndex((i) => i.id === 'decodeResults')
                "
              />

              <!-- MATRIX RESULTS -->
              <matrix-results
                v-if="
                  selectedResultsTab ===
                  resultsTabs.findIndex((i) => i.id === 'matrixSearchResults')
                "
              />

              <!-- CUSTOM RESULTS -->
              <custom-results
                v-if="
                  selectedResultsTab ===
                  resultsTabs.findIndex((i) => i.id === 'customResults')
                "
              />
            </div>
          </template>
        </base-tabs>
      </div>
    </template>

    <template #pageContent>
      <!-- MATRIX VIEW -->
      <matrix-view v-if="showMatrix" />
    </template>
  </side-panel-layout>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import STORE_ENUMS from "@/store/enums";

// COMPONENTS
import SidePanelLayout from "@/components/layouts/SidePanelLayout/SidePanelLayout";
import MatrixView from "@/components/torahCodeProgram/MatrixView/MatrixView";
import DecodeResults from "@/components/torahCodeProgram/Results/DecodeResults/DecodeResults";
import ProgressIndicator from "@/components/torahCodeProgram/Search/ProgressIndicator/ProgressIndicator";
import SearchForm from "@/components/torahCodeProgram/Search/SearchForm/SearchForm";
import BaseTabs from "@/components/shared/BaseTabs/BaseTabs";
import CustomResults from "@/components/torahCodeProgram/Results/CustomResults/CustomResults";
import MatrixResults from "@/components/torahCodeProgram/Results/MatrixResults/MatrixResults";
import SearchInProgress from "@/components/torahCodeProgram/Search/SearchInProgress/SearchInProgress";

export default {
  name: "TorahCodeProgram",
  components: {
    SidePanelLayout,
    MatrixView,
    DecodeResults,
    ProgressIndicator,
    SearchForm,
    BaseTabs,
    CustomResults,
    MatrixResults,
    SearchInProgress,
  },
  data() {
    return {
      EVENTS,
      selectedResultsTab: 0,
      resultsTabs: [
        {
          id: "decodeResults",
          title: "Search Results",
        },
        {
          id: "matrixSearchResults",
          title: "Matrix Results",
        },
        {
          id: "customResults",
          title: "Custom Results",
        },
      ],
    };
  },
  watch: {
    matrixSearchInProgress(val) {
      if (val) {
        // Set matrix tab active tab
        const matrixResultsTab = this.resultsTabs.findIndex(
          (t) => t.id === "matrixSearchResults"
        );
        if (matrixResultsTab !== -1) {
          this.selectedResultsTab = matrixResultsTab;
        }
      }
    },
  },
  computed: {
    showMatrix() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].show;
    },
    decodeInProgress() {
      return !!this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_RESULTS]
        .progress;
    },
    showResults() {
      return (
        this.decodeInProgress ||
        this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_RESULTS].results.length
      );
    },
    matrixTerm() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].matrixTerm;
    },
    matrixSearchInProgress() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX]
        .matrixSearchProgress;
    },
  },
  methods: {
    alertBeforeUnload(e) {
      if (this.decodeInProgress || this.showResults || this.showMatrix) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
  },
  created() {
    window.addEventListener("beforeunload", (e) => {
      this.alertBeforeUnload(e);
    });
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.alertBeforeUnload);
  },
};
</script>

<style lang="scss" scoped>
.sidepanel_layout {
  .logo_progress {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex: 1;

    .logo {
      color: var(--snow-grey);
      font-family: var(--font-montserrat);
      margin: 0;
      width: 100%;
      font-size: 26px;

      @media only screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }

  .search_details_container {
    overflow-x: auto;
  }

  .sidepanel {
    color: var(--snow-grey);
    height: 100%;

    .tabs {
      height: 100%;

      .results {
        background-color: var(--snow-grey);
        overflow: auto;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        ::v-deep .paginated_list .list {
          max-height: calc(100vh - 210px);
        }
      }
    }
  }
}
</style>
