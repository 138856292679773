<template>
  <div class="actions_menu">
    <base-button
      :class="{
        actions_menu_btn: true,
        open: showMenu,
      }"
      :id="`${id}_actions_menu_open`"
      :prefixIcon="ICONS.ACTIONS_MENU"
      :theme="'CLEAR'"
      @[EVENTS.CLICK]="menuBtnClicked"
    />

    <ul
      v-if="showMenu"
      class="menu_list"
      ref="menu"
      tabindex="0"
      @[EVENTS.BLUR]="() => (showMenu = false)"
    >
      <li
        v-for="action in actions"
        :key="action.title"
        @[EVENTS.CLICK]="() => actionSelected(action.action)"
      >
        {{ action.title }}
      </li>
    </ul>
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import ICONS from "@/enums/icons";

// COMPONENTS
import BaseButton from "@/components/shared/BaseButton/BaseButton";

export default {
  name: "ActionsMenu",
  components: { BaseButton },
  props: {
    id: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      required: false,
      default: () => [],
      validator: (val) => val.every((item) => item?.action && item?.title),
    },
  },
  data() {
    return {
      EVENTS,
      ICONS,
      showMenu: false,
    };
  },
  computed: {},
  methods: {
    menuBtnClicked() {
      this.showMenu = !this.showMenu;
      this.$nextTick(() => {
        this.$refs?.menu?.focus();
      });
    },

    actionSelected(action) {
      this.showMenu = false;
      this.$emit(action);
    },
  },
};
</script>

<style lang="scss" scoped>
.actions_menu {
  position: relative;

  .actions_menu_btn {
    width: 30px;
    height: 30px;

    &.open {
      background-color: var(--snow-grey);
    }
  }

  .menu_list {
    width: max-content;
    position: absolute;
    padding: 0;
    list-style: none;
    top: 0px;
    z-index: 1;
    color: var(--snow-grey);
    border-radius: 10px;
    left: 32px;

    li {
      border-bottom: 1px solid var(--snow-grey);
      padding: 10px 20px;
      cursor: pointer;
      background: var(--midnight-blue);

      &:first-of-type {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-of-type {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:hover {
        background-color: var(--aqua-blue);
      }
    }
  }
}
</style>
