var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"result_style_form"},[_c('div',{staticClass:"word_wrapper"},[_c('p',{staticClass:"word"},_vm._l((_vm.result.word),function(letter,idx){return _c('span',{key:letter + idx,staticClass:"letter",style:({
          backgroundColor: _vm.bgColor,
          color: _vm.color,
          border: _vm.borderColor
            ? `2px solid ${_vm.borderColor}`
            : '2px solid transparent',
          borderRadius: _vm.shape === _vm.ENUMS.SHAPES.SQUARE ? '4px' : '50%',
        })},[_vm._v(_vm._s(letter))])}),0)]),_c('div',{staticClass:"color section"},[_c('strong',[_vm._v("Letter Color")]),_c('color-picker',{attrs:{"colors":_vm.CONFIG.MATRIX.COLORS,"selected":_vm.color},on:_vm._d({},[_vm.EVENTS.SELECT,($event) => (_vm.color = $event)])})],1),_c('div',{staticClass:"background_color section"},[_c('strong',[_vm._v("Background Color")]),_c('color-picker',{attrs:{"colors":_vm.CONFIG.MATRIX.COLORS,"selected":_vm.bgColor},on:_vm._d({},[_vm.EVENTS.SELECT,($event) => (_vm.bgColor = $event)])})],1),_c('div',{staticClass:"border_color section"},[_c('strong',[_vm._v("Border Color")]),_c('color-picker',{attrs:{"colors":_vm.CONFIG.MATRIX.COLORS,"selected":_vm.borderColor},on:_vm._d({},[_vm.EVENTS.SELECT,($event) => (_vm.borderColor = $event)])})],1),_c('div',{staticClass:"shape section"},[_c('strong',[_vm._v("Shape")]),_c('shape-picker',{attrs:{"selected":_vm.shape},on:_vm._d({},[_vm.EVENTS.SELECT,($event) => (_vm.shape = $event)])})],1),_c('base-button',{attrs:{"id":"result_style_form_submit","text":"Save"},on:_vm._d({},[_vm.EVENTS.CLICK,_vm.saveStyle])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }