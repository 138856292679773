import STORE_ENUMS from "./enums";
import CONFIG from "@/screens/TorahCodeProgram/config";

export default {
  DEFAULT_STATES: {
    [STORE_ENUMS.STATE_KEYS.DECODE_QUERY_PARAMS]: {
      startIdx: CONFIG.TORAH_INDEXES.MIN,
      endIdx: CONFIG.TORAH_INDEXES.MAX,
      searchTerms: [],
      minSkip: 1,
      maxSkip: 1,
    },

    [STORE_ENUMS.STATE_KEYS.DECODE_RESULTS]: {
      results: [],
      selected: [],
      custom: [],
      progress: null,
    },
    [STORE_ENUMS.STATE_KEYS.MATRIX]: {
      width: CONFIG.MATRIX.DEFAULT_WIDTH,
      height: CONFIG.MATRIX.DEFAULT_HEIGHT,
      skip: 1,
      matrixTerm: null,
      matrixLetters: null,
      show: false,
      matrixSearchProgress: false,
      matrixResults: [],
      tools: {
        selectedColor: null,
        paint: false,
      },
      currentCustomResult: null,
    },
  },
};
