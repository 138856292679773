<template>
  <div class="text">
    <div
      :style="`display: grid; grid-template-columns: ${gridColumns}; column-gap: 10px; row-gap: 10px; justify-content: center;`"
    >
      <p
        :style="{
          textAlign: 'center',
          background: getLetterBackground(item),
          color: getLetterColor(item),
          border: getLetterBorder(item),
          borderRadius: getLetterBorderRadius(item),
          cursor: selectedColor ? 'pointer' : 'initial',
        }"
        v-for="(item, i) in matrixLetters"
        :key="i"
        :class="{
          disabled: isLetterDisabled(item),
        }"
        @[EVENTS.CLICK]="() => paintLetter(item)"
      >
        {{ item.value }}
      </p>
    </div>
  </div>
</template>

<script>
// ENUMS
import STORE_ENUMS from "@/store/enums";
import EVENTS from "@/enums/events";
import ENUMS from "@/enums/enums";

// STYLES
import COLORS from "@/styles/colors";

export default {
  name: "TorahMatrix",
  data() {
    return { EVENTS };
  },
  computed: {
    matrixLetters() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].matrixLetters;
    },
    lettersPerRow() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].width;
    },
    selectedResults() {
      return (
        this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_RESULTS].selected || []
      );
    },
    customResults() {
      return (
        this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_RESULTS].custom || []
      );
    },
    paintActive() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].tools.paint;
    },
    selectedColor() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].tools
        .selectedColor;
    },
    currentCustomResult() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX]
        .currentCustomResult;
    },
    gridColumns() {
      let columns = "";
      for (let i = 0; i < this.lettersPerRow; i++) {
        columns += "22px";
        if (i < this.lettersPerRow - 1) {
          columns += " ";
        }
      }
      return columns;
    },
  },
  methods: {
    paintLetter(letterItem) {
      if (!this.selectedColor) return;
      let result;
      if (this.currentCustomResult) {
        result = { ...this.currentCustomResult };
        result.id = result.id + letterItem.idx.toString();
        result.word = result.word + letterItem.value;
        result.letterIndexes.push(letterItem.idx);

        if (!result.skip) {
          result.skip = result.letterIndexes[1] - result.letterIndexes[0];
        }
      } else {
        result = {
          id: letterItem.idx.toString() + "_custom",
          word: letterItem.value,
          letterIndexes: [letterItem.idx],
          style: {
            backgroundColor: this.selectedColor,
            color: COLORS.WHITE,
          },
        };
      }
      this.$store.commit(STORE_ENUMS.MUTATIONS.SHARED.UPDATE_VALUE, {
        stateKey: STORE_ENUMS.STATE_KEYS.MATRIX,
        field: "currentCustomResult",
        value: result,
      });
    },
    isLetterDisabled(item) {
      return (
        this.currentCustomResult?.letterIndexes.includes(item.idx) ||
        (this.currentCustomResult?.skip &&
          this.currentCustomResult?.letterIndexes[
            this.currentCustomResult?.letterIndexes?.length - 1
          ] +
            this.currentCustomResult?.skip !==
            item.idx)
      );
    },
    isLetterSelected(item) {
      const selectedIdx = this.selectedResults.findIndex((word) =>
        word.letterIndexes.includes(item.idx)
      );
      return selectedIdx === -1 ? false : selectedIdx + 1;
    },
    isLetterPartOfCustomResults(item) {
      const customResultIdx = this.customResults?.findIndex((result) =>
        result.letterIndexes.includes(item.idx)
      );
      return customResultIdx === -1 ? false : customResultIdx + 1;
    },
    getLetterColor(item) {
      if (this.isLetterDisabled(item)) {
        return COLORS.SNOW_GREY;
      }

      const selectedIdx = this.isLetterSelected(item);
      if (selectedIdx) {
        return this.selectedResults[selectedIdx - 1].style.color;
      }

      return COLORS.MIDNIGHT_BLUE;
    },
    getLetterBackground(item) {
      if (this.currentCustomResult?.letterIndexes?.includes(item.idx)) {
        return this.selectedColor;
      }

      if (this.isLetterDisabled(item)) {
        return COLORS.TRANSPARENT;
      }

      const selectedIdx = this.isLetterSelected(item);
      if (selectedIdx) {
        return this.selectedResults[selectedIdx - 1].style.backgroundColor;
      }

      return COLORS.TRANSPARENT;
    },
    getLetterBorder(item) {
      const selectedIdx = this.isLetterSelected(item);

      if (
        this.isLetterDisabled(item) ||
        !selectedIdx ||
        !this.selectedResults[selectedIdx - 1].style.borderColor
      ) {
        return "2px solid transparent";
      }
      return (
        "2px solid " + this.selectedResults[selectedIdx - 1].style.borderColor
      );
    },
    getLetterBorderRadius(item) {
      const selectedIdx = this.isLetterSelected(item);

      if (this.isLetterDisabled(item) || !selectedIdx) {
        return "4px";
      }
      return this.selectedResults[selectedIdx - 1].style.shape ===
        ENUMS.SHAPES.CIRCLE
        ? "50%"
        : "4px";
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  direction: rtl;
  width: fit-content;
  margin: 0 auto;

  p {
    border-radius: 2px;
    margin: 0;
    font-family: var(--font-rubik);

    &.disabled {
      pointer-events: none;
    }
  }
}
</style>
