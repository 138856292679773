<template>
  <div class="layout">
    <div v-if="topBar" class="top_bar">
      <base-button
        id="oepn_side_panel_btn"
        class="oepn_side_panel_btn"
        :prefixIcon="ICONS.HAMBURGER_NAV"
        @[EVENTS.CLICK]="() => (showSidePanel = !showSidePanel)"
      />

      <div class="top_bar_content">
        <slot name="topBarContent" />
      </div>
    </div>

    <div class="side_panel_layout" :style="sidePanelLayoutStyles">
      <!-- SIDE PANEL -->
      <div
        :class="{
          side_panel: true,
          closed: !showSidePanel,
        }"
      >
        <div class="side_panel_content">
          <slot name="sidePanelContent" />
        </div>
      </div>

      <!-- PAGE CONTENT -->
      <div class="page_content">
        <slot name="pageContent" />
      </div>
    </div>
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import ICONS from "@/enums/icons";

// STYLES
import THEME from "./theme";

// COMPONENTS
import BaseButton from "@/components/shared/BaseButton/BaseButton";

export default {
  name: "SidePanelLayout",
  components: { BaseButton },
  props: {
    sidePanelWidth: {
      type: Number,
      required: false,
      default: 30,
    },
    theme: {
      type: String,
      required: false,
      default: "PRIMARY",
    },
    topBar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      EVENTS,
      ICONS,
      showSidePanel: true,
    };
  },
  computed: {
    sidePanelLayoutStyles() {
      return {
        "--side-panel-width": this.sidePanelWidth + "%",
        "--page-content-width":
          (this.showSidePanel ? 100 - this.sidePanelWidth : 100) + "%",
        "--side-panel-bg-color": THEME[this.theme].SIDE_PANEL_BACKGROUND,
        "--top-bar-height": (this.topBar ? 60 : 0) + "px",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.top_bar {
  height: 60px;
  background: var(--midnight-blue);
  position: sticky;
  top: 0;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 1rem;

  .oepn_side_panel_btn {
    background-color: transparent;
  }

  .top_bar_content {
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
  }
}
.side_panel_layout {
  display: flex;
  overflow: hidden;
  height: calc(100vh - var(--top-bar-height));

  .side_panel {
    height: calc(100vh - var(--top-bar-height));
    overflow-y: hidden;
    width: var(--side-panel-width);
    max-width: 450px;
    background-color: var(--side-panel-bg-color);
    transition: 0.4s ease-in-out all;

    @media only screen and (max-width: 850px) {
      position: absolute;
      width: 50%;
    }

    @media only screen and (max-width: 500px) {
      width: 100%;
    }

    .side_panel_content {
      transition: 0.2s ease-in-out all;
      padding: 20px;
      height: 100%;
      // overflow: hidden;
    }

    &.closed {
      width: 0px;
    }

    .close_btn_container {
      display: flex;
      justify-content: flex-end;
    }

    .side_panel_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .page_content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
