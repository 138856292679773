import Vue from "vue";
import App from "@/App.vue";
import store from "@/store/store";
import { inject } from "@vercel/analytics";
import "@/config/fontAwesomeIcons";
import "@/styles/variables.scss";

Vue.config.productionTip = false;
Vue.prototype.$store = store;

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");

inject();
