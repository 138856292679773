import { Result } from "@/models/ResultModel";
import STORE_ENUMS from "@/store/enums";

const stateKey = STORE_ENUMS.STATE_KEYS.MATRIX;
const mutations = STORE_ENUMS.MUTATIONS.MATRIX;

export default {
  [mutations.SET_STATE]: (state, params) => {
    state[stateKey] = { ...state[stateKey], ...params };
  },
  [mutations.UPDATE_TOOL_VALUE]: (state, { field, value }) => {
    state[stateKey].tools[field] = value;
  },
  [mutations.UPDATE_MATRIX_SEARCH_PROGRESS]: (state, value) => {
    state[stateKey].matrixSearchProgress = value;
  },
  [mutations.ADD_MATRIX_RESULTS]: (state, newResults) => {
    const resultsToAdd = newResults.filter(
      (r) =>
        !state[stateKey].matrixResults.some(
          (mR) => mR.letterIndexes.join("") === r.letterIndexes.join("")
        )
    );
    state[stateKey].matrixResults = state[stateKey].matrixResults.concat(
      resultsToAdd.map((r) => new Result(r))
    );
  },
};
