<template>
  <div
    :class="{
      result_card: true,
      selected: selected,
    }"
  >
    <actions-menu
      class="actions_menu"
      :id="result.id"
      :actions="actions"
      @[EVENTS.GENERATE_MATRIX]="generateResultMatrix"
      @[EVENTS.DELETE]="$emit(EVENTS.DELETE)"
      @[EVENTS.EDIT]="toggleEditStyle"
    />
    <div class="result_data" @[EVENTS.CLICK]="$emit(EVENTS.SELECT)">
      <div
        class="selected_banner"
        :style="{
          backgroundColor: selected
            ? result.style.backgroundColor === COLORS.WHITE
              ? result.style.color
              : result.style.backgroundColor
            : COLORS.WHITE,
        }"
      ></div>
      <div class="data">
        <p
          :class="{ word: true, selected: selected }"
          :style="{
            backgroundColor: selected
              ? result.style.backgroundColor
              : COLORS.SNOW_GREY,
            color: selected ? result.style.color : COLORS.MIDNIGHT_BLUE,
            border:
              selected && result.style.borderColor
                ? '2px solid ' + result.style.borderColor
                : '2px solid transparent',
          }"
        >
          {{ result.word }}
        </p>
        <p>Skip: {{ result.skip.toLocaleString() }}</p>
        <p class="index_list_title">
          Indexes: {{ result.letterIndexes[0].toLocaleString() }} -
          {{
            result.letterIndexes[
              result.letterIndexes.length - 1
            ].toLocaleString()
          }}
        </p>
      </div>
    </div>

    <screen-popup
      v-if="showEditStyleForm"
      class="result_style_popup"
      overlay
      @[EVENTS.CLOSE]="toggleEditStyle"
    >
      <template #content>
        <result-style-form :result="result" @[EVENTS.SAVE]="toggleEditStyle" />
      </template>
    </screen-popup>
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";

// MODELS
import ResultModel from "@/models/ResultModel";

// STYLES
import COLORS from "@/styles/colors";

// HELPERS
import matrixBuilderHelper from "@/helpers/torahDecoder/matrixBuilder";

// COMPONENTS
import ActionsMenu from "@/components/shared/ActionsMenu/ActionsMenu";
import ScreenPopup from "@/components/shared/ScreenPopup/ScreenPopup";
import ResultStyleForm from "@/components/torahCodeProgram/Results/ResultStyleForm/ResultStyleForm";

export default {
  name: "ResultCard",
  components: { ActionsMenu, ScreenPopup, ResultStyleForm },
  props: {
    result: {
      type: ResultModel,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      EVENTS,
      COLORS,
      showEditStyleForm: false,
    };
  },
  computed: {
    actions() {
      const actions = [
        {
          title: "Generate Matrix",
          action: EVENTS.GENERATE_MATRIX,
        },
        {
          title: "Edit Style",
          action: EVENTS.EDIT,
        },
      ];

      if (this.canDelete) {
        actions.push({
          title: "Delete",
          action: EVENTS.DELETE,
        });
      }

      return actions;
    },
  },
  methods: {
    generateResultMatrix() {
      if (!this.selected) {
        this.$emit(EVENTS.SELECT);
      }
      matrixBuilderHelper.generateResultMatrix({
        width: 50,
        height: this.result.word.length < 10 ? 10 : this.result.word.length,
        skip: this.result.skip,
        matrixTerm: this.result,
        resetResults: true,
      });
    },
    toggleEditStyle() {
      this.showEditStyleForm = !this.showEditStyleForm;
    },
  },
};
</script>

<style lang="scss" scoped>
.result_card {
  background: var(--white);
  border-radius: 4px;
  color: var(--midnight-blue);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: 0.2s ease-in-out all;

  .actions_menu {
    position: absolute;
    left: 30px;
    top: 10px;
    z-index: 1;

    ::v-deep button {
      padding: 0;
    }
  }

  .word {
    padding: 5px;
    border-radius: 4px;
    text-align: right;
    width: fit-content;
    height: fit-content;
    justify-self: flex-end;
    font-family: var(--font-rubik);
  }

  .selected_banner {
    width: 18px;
    background-color: var(--white);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 2px solid var(--snow-grey);
    transition: 0.2s ease-in-out all;
  }

  &.selected {
    .word {
      color: var(--white);
    }
  }

  .result_data {
    display: flex;
    flex: 1;
    cursor: pointer;

    .data {
      display: grid;
      flex: 1;
      padding: 10px;
    }
  }

  p {
    margin: 0;
  }

  .result_style_popup ::v-deep .content {
    top: 20%;

    @media only screen and (max-width: 500px) {
      top: 10%;
    }
  }
}
</style>
