import COLORS from "@/styles/colors";

export class Result {
  constructor(objInit = {}) {
    this.id = objInit.id;
    this.word = objInit.word;
    this.skip = objInit.skip;
    this.letterIndexes = objInit.letterIndexes;
    this.style = new ResultStyle(objInit.style);
  }

  updateStyle(field, value) {
    this.style[field] = value;
  }
}

class ResultStyle {
  constructor(objInit = {}) {
    this.backgroundColor = objInit.backgroundColor || COLORS.AQUA_BLUE;
    this.color = objInit.color || COLORS.MIDNIGHT_BLUE;
    this.borderColor = objInit.borderColor;
    this.shape = objInit.shape;
  }
}
