<template>
  <div class="base_pagination">
    <div class="arrows_wrapper">
      <base-button
        class="paginate_arrows"
        id="paginate_to_first_btn"
        :disabled="selected === 1"
        :prefixIcon="ICONS.ARROW_DOUBLE_LEFT"
        theme="CLEAR"
        @[EVENTS.CLICK]="jumpToFirst"
      />

      <base-button
        class="paginate_arrows"
        id="paginate_back_btn"
        :disabled="selected === 1"
        :prefixIcon="ICONS.ARROW_LEFT"
        theme="CLEAR"
        @[EVENTS.CLICK]="paginateBack"
      />
    </div>

    <ul class="page_list">
      <li
        v-for="page in displayedPages"
        :class="{
          selected: page === selected,
        }"
        :key="page"
        @[EVENTS.CLICK]="$emit(EVENTS.SELECT, page)"
      >
        {{ page }}
      </li>
    </ul>

    <div class="arrows_wrapper">
      <base-button
        class="paginate_arrows"
        id="paginate_back_btn"
        :disabled="selected === pages"
        :prefixIcon="ICONS.ARROW_RIGHT"
        theme="CLEAR"
        @[EVENTS.CLICK]="paginateNext"
      />

      <base-button
        class="paginate_arrows"
        id="paginate_to_first_btn"
        :disabled="selected === pages"
        :prefixIcon="ICONS.ARROW_DOUBLE_RIGHT"
        theme="CLEAR"
        @[EVENTS.CLICK]="jumpToLast"
      />
    </div>
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";

// STYLES
import ICONS from "@/enums/icons";

// COMPONENTS
import BaseButton from "@/components/shared/BaseButton/BaseButton";

export default {
  name: "BasePagination",
  components: { BaseButton },
  props: {
    pages: {
      type: Number,
      required: false,
      default: 1,
    },
    selected: {
      type: Number,
      required: false,
      default: 1,
    },
    maxDisplayed: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      EVENTS,
      ICONS,
    };
  },
  computed: {
    displayedPages() {
      const cols = Math.floor(this.maxDisplayed / 2);
      const pages = [];
      if (this.pages <= this.maxDisplayed) {
        for (let i = 1; i <= this.pages; i++) {
          pages.push(i);
        }
      } else {
        for (let i = cols; i >= 1; i--) {
          if (this.selected - i >= 1) {
            pages.push(this.selected - i);
          }
        }
        pages.push(this.selected);
        const afterCols =
          this.maxDisplayed - pages.length > cols
            ? this.maxDisplayed - pages.length
            : cols;
        for (let i = 1; i <= afterCols; i++) {
          if (this.selected + i <= this.pages) {
            pages.push(this.selected + i);
          }
        }
      }
      return [...pages];
    },
  },
  methods: {
    paginateBack() {
      this.$emit(EVENTS.SELECT, this.selected - 1);
    },
    jumpToFirst() {
      this.$emit(EVENTS.SELECT, 1);
    },
    paginateNext() {
      this.$emit(EVENTS.SELECT, this.selected + 1);
    },
    jumpToLast() {
      this.$emit(EVENTS.SELECT, this.pages);
    },
  },
};
</script>

<style lang="scss" scoped>
.base_pagination {
  display: flex;
  justify-content: space-between;
  background-color: var(--aqua-blue);
  color: var(--white);

  .arrows_wrapper {
    display: flex;
    align-items: center;
    padding: 0px 5px;

    .paginate_arrows {
      padding: 0;
      transition: 0.2s ease-in-out all;

      ::v-deep svg {
        color: var(--white);
        width: 30px;
        font-size: 16px;
      }

      &:disabled {
        background-color: initial;

        ::v-deep svg {
          color: var(--aqua-blue);
        }
      }

      &:hover {
        background-color: initial;
      }
    }
  }

  .page_list {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    margin: 0;
    padding: 10px 0;

    li {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      &.selected {
        background-color: var(--white);
        color: var(--aqua-blue);
        border-radius: 4px;
      }
    }
  }
}
</style>
