<template>
  <div class="matrix_view">
    <div class="top_section">
      <div class="matrix_actions">
        <!-- MATRIX CONFIG -->
        <div class="section">
          <matrix-config />

          <base-button
            id="show_search_in_matrix_btn"
            :class="{
              active: showSearchInMatrix,
            }"
            title="Search in Matrix"
            :prefixIcon="ICONS.SEARCH"
            theme="SECONDARY"
            @[EVENTS.CLICK]="toggleSearchInMatrix"
          />

          <base-button
            id="show_toolbar_matrix_btn"
            :class="{
              active: showToolbar,
            }"
            title="Show Highlight Words Tool"
            theme="SECONDARY"
            :prefixIcon="ICONS.PALETTE"
            @[EVENTS.CLICK]="toggleToolbar"
          />
        </div>

        <!-- RESET MATRIX BUTTON -->
        <base-button
          id="reset_matrix_btn"
          class="reset_btn"
          text="Reset"
          theme="SECONDARY_INVERT"
          @[EVENTS.CLICK]="resetMatrix"
        />
      </div>

      <div>
        <p v-if="showSearchInMatrix || showToolbar" class="form_title">
          {{ showSearchInMatrix ? "Search In Matrix" : "Highlight Word" }}
        </p>

        <search-in-matrix v-if="showSearchInMatrix" />

        <matrix-tool-bar
          v-if="showToolbar"
          :paintActive="true"
          :selectedColor="selectedColor"
          @[EVENTS.ACTION]="updateTools"
        />
      </div>
    </div>

    <div class="custom_results">
      <p
        v-for="result in selectedResults"
        class="result"
        :key="result.id"
        :style="{
          backgroundColor: result.style.backgroundColor,
          color: result.style.color,
          border: result.style.borderColor
            ? '2px solid ' + result.style.borderColor
            : '2px solid transparent',
        }"
      >
        {{ result.word }}
        <span v-if="result.skip"> -{{ result.skip.toLocaleString() }} </span>
      </p>
    </div>

    <div class="matrix_section" ref="matrixSection">
      <torah-matrix />
    </div>
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import STORE_ENUMS from "@/store/enums";
import ICONS from "@/enums/icons";

// HELPERS
import matrixBuilder from "@/helpers/torahDecoder/matrixBuilder";

// STYLES
import COLORS from "@/styles/colors";

// COMPONENTS
import TorahMatrix from "./components/TorahMatrix.vue";
import MatrixToolBar from "./components/MatrixToolBar";
import BaseButton from "@/components/shared/BaseButton/BaseButton";
import SearchInMatrix from "./components/SearchInMatrix.vue";
import MatrixConfig from "@/components/torahCodeProgram/MatrixConfig/MatrixConfig";

export default {
  name: "MatrixView",
  components: {
    TorahMatrix,
    MatrixToolBar,
    BaseButton,
    SearchInMatrix,
    MatrixConfig,
  },
  data() {
    return {
      EVENTS,
      COLORS,
      ICONS,
      showSearchInMatrix: false,
      showToolbar: false,
    };
  },
  watch: {
    showToolbar(val) {
      if (!val && this.selectedColor) {
        this.updateTools({
          action: EVENTS.UPDATE,
          field: "selectedColor",
          value: null,
        });
        this.$store.commit(STORE_ENUMS.MUTATIONS.SHARED.UPDATE_VALUE, {
          stateKey: STORE_ENUMS.STATE_KEYS.MATRIX,
          field: "currentCustomResult",
          value: null,
        });
      }
    },
  },
  computed: {
    paintActive() {
      return (
        this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX]?.tools?.paint || false
      );
    },
    selectedColor() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].tools
        .selectedColor;
    },
    selectedResults() {
      return (
        this.$store.state[STORE_ENUMS.STATE_KEYS.DECODE_RESULTS]?.selected || []
      );
    },
  },
  methods: {
    updateTools({ action, field, value }) {
      if (action === EVENTS.UPDATE) {
        this.$store.commit(STORE_ENUMS.MUTATIONS.MATRIX.UPDATE_TOOL_VALUE, {
          field,
          value,
        });
      }
    },
    toggleToolbar() {
      this.showToolbar = !this.showToolbar;
      this.showSearchInMatrix = false;
    },
    toggleSearchInMatrix() {
      this.showSearchInMatrix = !this.showSearchInMatrix;
      this.showToolbar = false;
    },
    resetMatrix() {
      this.showSearchInMatrix = false;
      this.showToolbar = false;

      matrixBuilder.generateResultMatrix({
        width: 50,
        height: 10,
        skip: this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].matrixTerm.skip,
        matrixTerm: this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].matrixTerm,
        resetResults: true,
      });

      this.scrollToMatrixCenter();
    },
    scrollToMatrixCenter() {
      const scrollContainer = this.$refs.matrixSection;

      if (scrollContainer) {
        scrollContainer.scrollLeft = scrollContainer.scrollWidth / 4;
      }
    },
  },
  mounted() {
    this.scrollToMatrixCenter();
  },
};
</script>

<style lang="scss" scoped>
.matrix_view {
  display: grid;

  .top_section {
    display: grid;
    padding: 10px 20px 20px 20px;
    background: #dce6f1;
    margin-bottom: 20px;

    .reset_btn {
      margin-top: 16px;
    }

    .matrix_actions {
      display: flex;
      justify-content: space-between;
      gap: 18px;

      .section {
        display: flex;
        gap: 0 2rem;
        flex-wrap: wrap;

        ::v-deep .base_button {
          margin-top: 18px;
        }

        .active {
          background-color: var(--aqua-blue);
        }
      }
    }

    .form_title {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      padding: 20px 0px 10px 0px;
    }
  }

  .matrix_section {
    padding: 20px;
    max-width: 100%;
    overflow: scroll;
  }

  .custom_results {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0px 10px;
    font-family: var(--font-rubik);

    .result {
      margin: 0;
      border-radius: 4px;
      padding: 5px;
    }
  }
}
</style>
