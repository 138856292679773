<template>
  <div class="matrix_config">
    <base-form
      class="matrix_form"
      :fields="fields"
      btnText="Update Matrix"
      @[EVENTS.SUBMIT]="submit"
    />
  </div>
</template>

<script>
// ENUMS
import EVENTS from "@/enums/events";
import STORE_ENUMS from "@/store/enums";

// HELPERS
import matrixBuilder from "@/helpers/torahDecoder/matrixBuilder";

// MODELS
import { FormField } from "@/models/FormField";

// COMPONENTS
import BaseForm from "@/components/shared/BaseForm/BaseForm";

export default {
  name: "MatrixConfig",
  components: { BaseForm },
  data: () => {
    return {
      EVENTS,
      localWidth: null,
      localHeight: null,
      localSkip: null,
    };
  },
  computed: {
    width() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].width;
    },
    height() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].height;
    },
    skip() {
      return this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].skip;
    },
    fields() {
      return [
        new FormField({
          key: "width",
          label: "Width",
          value: this.width,
          type: "number",
          validators: [
            {
              validation: (value) => value > 0,
              errorMessage: "Value must be greater than 0.",
            },
            {
              validation: (value) => value <= 500,
              errorMessage: "Value must be less than or equal to 500.",
            },
          ],
        }),
        new FormField({
          key: "height",
          label: "Height",
          value: this.height,
          type: "number",
          validators: [
            {
              validation: (value) => value > 0,
              errorMessage: "Value must be greater than 0.",
            },
            {
              validation: (value) => value <= 500,
              errorMessage: "Value must be less than or equal to 500.",
            },
          ],
        }),
        new FormField({
          key: "skip",
          label: "Skip",
          value: this.skip,
          type: "number",
          validators: [
            {
              validation: (value) => value >= 30,
              errorMessage: "Value must be greater than or equal to 30.",
            },
            {
              validation: (value) => value <= 152402,
              errorMessage: "Value must be less than or equal to 152,402.",
            },
            {
              validation: (value, form) => value >= form.width.value,
              errorMessage: "Value must be greater than or equal to Width.",
            },
          ],
        }),
      ];
    },
  },
  methods: {
    submit(form) {
      this.generateMatrix(form);
    },
    generateMatrix(form) {
      matrixBuilder.generateResultMatrix({
        width: form.width,
        height: form.height,
        skip: form.skip,
        matrixTerm: this.$store.state[STORE_ENUMS.STATE_KEYS.MATRIX].matrixTerm,
        resetResults: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.matrix_config {
  display: flex;
  gap: 10px;

  .matrix_form {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;

    ::v-deep .field {
      max-width: 160px;
    }

    ::v-deep .base_button {
      margin-top: 18px;
      background-color: var(--midnight-blue);
    }
  }
}
</style>
