import { render, staticRenderFns } from "./MatrixResults.vue?vue&type=template&id=396a6f75&scoped=true&"
import script from "./MatrixResults.vue?vue&type=script&lang=js&"
export * from "./MatrixResults.vue?vue&type=script&lang=js&"
import style0 from "./MatrixResults.vue?vue&type=style&index=0&id=396a6f75&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396a6f75",
  null
  
)

export default component.exports