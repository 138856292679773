import store from "@/store/store";
import { TORAH } from "../../../public/data/torah";
import STORE_ENUMS from "@/store/enums";
import COLORS from "@/styles/colors";

const resetMatrix = (matrixTerm) => {
  store.commit(STORE_ENUMS.MUTATIONS.SHARED.RESET_STATE, {
    stateKey: STORE_ENUMS.STATE_KEYS.MATRIX,
  });
  store.commit(STORE_ENUMS.MUTATIONS.SHARED.UPDATE_VALUE, {
    stateKey: STORE_ENUMS.STATE_KEYS.DECODE_RESULTS,
    field: "selected",
    value: [matrixTerm],
  });
  store.commit(STORE_ENUMS.MUTATIONS.SHARED.UPDATE_VALUE, {
    stateKey: STORE_ENUMS.STATE_KEYS.DECODE_RESULTS,
    field: "custom",
    value: [],
  });
};

const generateResultMatrix = ({
  width,
  height,
  skip,
  matrixTerm,
  resetResults,
}) => {
  skip = skip < 30 ? 30 + (30 % skip) : skip;
  width = width <= skip ? width : skip;
  const resultIndexes = matrixTerm.letterIndexes;
  const middleCol = [resultIndexes[Math.floor(resultIndexes.length / 2)]]; // starts wih middle result letter

  // BUILDING MIDDLE COL INDEXES - adds and subtracts matrix skip from middle index
  for (let i = 1; i <= Math.ceil(height / 2); i++) {
    if (middleCol.length == height) {
      break;
    }
    if (middleCol[0] - skip >= 0) {
      middleCol.unshift(middleCol[0] - skip);
      if (middleCol.length == height) {
        break;
      }
    }
    if (middleCol[0] + skip <= TORAH.length - 1) {
      middleCol.push(middleCol[middleCol.length - 1] + skip);
    }
  }

  // BUILDING MATRIX
  let matrixLetters = [];
  // create matrix rows
  for (let rowIndex = 0; rowIndex < middleCol.length; rowIndex++) {
    const row = [];
    // BACK ROW - add letters from middle index backwards
    for (
      let j =
        width % 2 === 0 ? Math.floor(width / 2) - 1 : Math.floor(width / 2); // -1 in case of even width number
      j > 0;
      j--
    ) {
      if (middleCol[rowIndex] - j >= 0) {
        row.push({
          value: TORAH[middleCol[rowIndex] - j],
          idx: middleCol[rowIndex] - j,
        });
      }
    }
    // LETTER - middle index
    row.push({
      value: TORAH[middleCol[rowIndex]],
      idx: middleCol[rowIndex],
    });

    // FRONT ROW - add letters from middle index forwards
    for (let j = 1; j <= Math.floor(width / 2); j++) {
      if (middleCol[rowIndex] + j <= TORAH.length - 1) {
        row.push({
          value: TORAH[middleCol[rowIndex] + j],
          idx: middleCol[rowIndex] + j,
        });
      }
    }
    matrixLetters = matrixLetters.concat(row);
  }
  if (resetResults) {
    resetMatrix(matrixTerm);
    matrixTerm.updateStyle("color", COLORS.WHITE);
  }

  // ADD MATRIX VALUES TO STORE
  store.commit(STORE_ENUMS.MUTATIONS.MATRIX.SET_STATE, {
    startIdx: matrixLetters[0],
    endIdx: matrixLetters[matrixLetters.length - 1],
    matrixLetters,
    show: true,
    width,
    height,
    skip,
    matrixTerm,
    matrixResults: resetResults
      ? []
      : store.state[STORE_ENUMS.STATE_KEYS.MATRIX].matrixResults,
  });
};

export default {
  generateResultMatrix,
};
