var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{style:(`display: grid; grid-template-columns: ${_vm.gridColumns}; column-gap: 10px; row-gap: 10px; justify-content: center;`)},_vm._l((_vm.matrixLetters),function(item,i){return _c('p',{key:i,class:{
        disabled: _vm.isLetterDisabled(item),
      },style:({
        textAlign: 'center',
        background: _vm.getLetterBackground(item),
        color: _vm.getLetterColor(item),
        border: _vm.getLetterBorder(item),
        borderRadius: _vm.getLetterBorderRadius(item),
        cursor: _vm.selectedColor ? 'pointer' : 'initial',
      }),on:_vm._d({},[_vm.EVENTS.CLICK,() => _vm.paintLetter(item)])},[_vm._v(" "+_vm._s(item.value)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }