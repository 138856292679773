<template>
  <font-awesome-icon :icon="icon" :style="iconStyles" />
</template>

<script>
import ICONS from "@/enums/icons";

export default {
  name: "BaseIcon",
  props: {
    icon: {
      type: String,
      required: true,
      validator: (val) => Object.values(ICONS).includes(val),
    },
    color: {
      type: String,
      required: false,
      default: "#000",
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    },
  },
  computed: {
    iconStyles() {
      return {
        "--icon-color": this.color,
        "--icon-size": this.size + "px",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  color: var(--icon-color);
  font-size: var(--icon-size);
}
</style>
