import COLORS from "@/styles/colors";

export default {
  PRIMARY: {
    BACKGROUND: COLORS.AQUA_BLUE,
    COLOR: COLORS.SNOW_GREY,
    HOVER: {
      BACKGROUND: COLORS.AQUA_BLUE,
      COLOR: COLORS.WHITE,
    },
  },
  SECONDARY: {
    BACKGROUND: COLORS.MIDNIGHT_BLUE,
    COLOR: COLORS.SNOW_GREY,
    HOVER: {
      BACKGROUND: COLORS.MIDNIGHT_BLUE,
      COLOR: COLORS.WHITE,
    },
  },
  SECONDARY_INVERT: {
    BACKGROUND: COLORS.WHITE,
    COLOR: COLORS.MIDNIGHT_BLUE,
    BORDER: "2px solid " + COLORS.MIDNIGHT_BLUE,
    HOVER: {
      BACKGROUND: COLORS.WHITE,
      COLOR: COLORS.MIDNIGHT_BLUE,
    },
  },
  CLEAR: {
    BACKGROUND: COLORS.TRANSPARENT,
    COLOR: COLORS.MIDNIGHT_BLUE,
    HOVER: {
      BACKGROUND: COLORS.SNOW_GREY,
      COLOR: COLORS.MIDNIGHT_BLUE,
    },
  },
};
